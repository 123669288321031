import React, { Component, Fragment } from "react";

class CalendlyWidget extends Component {
  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }

  render() {
    return (
      <div>
        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/alotaibithamer/call"
          style={{ Width: "120px", height: "860px" }}
        />
      </div>
    );
  }
}

export default CalendlyWidget;
