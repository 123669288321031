import React, { Component } from "react";
import "./App.css";

import { Link } from "react-router-dom";

// React-MDL imports
import { Layout, Header, Navigation, Drawer, Content } from "react-mdl";

// Main component Import
import Main from "./components/main";
import HomePageTemp from "./components/HomePageTemp";

class App extends Component {
  render() {
    return (
      // Imported from Layout / React-MDL
      <div className="demo-big-content">
        <Layout>
          <Header
            className="Navbar-color"
            title={
              <Link style={{ textDecoration: "none", color: "white" }} to="/">
                Thamer Alotaibi
              </Link>
            }
            scroll
          >
            <Navigation>
              <Link to="/ContactMe">Schedule a call with Me</Link>
            </Navigation>
          </Header>
          <Drawer
            title={
              <Link style={{ textDecoration: "none" }} to="/">
                Thamer Alotaibi
              </Link>
            }
          >
            <Navigation>
              <Link to="/ContactMe">Schedule a call with Me</Link>
            </Navigation>
          </Drawer>
          <Content>
            <div className="page-content" />
            <HomePageTemp />
          </Content>
        </Layout>
      </div>
    );
  }
}

export default App;
