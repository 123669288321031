import React, { Component } from "react";

// React-DML imports
import { Grid, Cell } from "react-mdl";

class HomePage extends Component {
  render() {
    return (
      <div>
        <Grid className="HomePageGrid">
          <Cell col={12}>
            <img
              src="https://www.shareicon.net/data/256x256/2015/09/24/106427_man_512x512.png"
              alt="avatar"
              className="avatar-img"
            />
            <div className="Profile-title-text">
              <h1>Developer</h1>
              <hr />
              <p>
                HTML/CSS | Javascript | React | Redux | NodeJS | ExpressJS |
                MongoDB | Firebase
              </p>

              <div className="social-links">
                {/* LinkedIn */}
                <a
                  href="https://www.linkedin.com/in/alotaibit/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fa fa-linkedin-square"></i>
                </a>

                {/* Github */}
                <a
                  href="https://github.com/alotaibit/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fa fa-github-square"></i>
                </a>

                {/* Twitter */}
                <a
                  href="http://www.twitter.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fa fa-twitter-square"></i>
                </a>
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    );
  }
}

export default HomePage;
